import React from "react";
import { TARIFFS } from "../../components/iframe/wa-listing-builder-paywall/index.content";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import Tariff from "../../components/iframe/wa-listing-builder-paywall/Tariff";
import ListBenefits from "../../components/iframe/wa-listing-builder-paywall/ListBenefits";
import "../../components/iframe/wa-listing-builder-paywall/index.scss";

const WaListingBuilderPaywall = () => {
  const {
    addProps: { software, checkoutLanguage },
    isRendered,
    paramsLink,
  } = useQueryWaIframeParams("wa-listing-builder");
  const { monthly, yearly } = TARIFFS;
  const category = `${software || "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return checkoutLanguage ? `/${checkoutLanguage}${link}` : link;
  };
  return isRendered ? (
    <Layout withTagManager={false} className="PgIWLBP" turnOnEventView>
      <p className="PgIWLBP__title">Pricing</p>
      <p className="PgIWLBP__subTitle">
        AI Listing Builder & AI Review Analyzer
      </p>
      <p className="PgIWLBP__text">
        Boost your product’s visibility and Amazon ranking by using a
        perfectly-created product listing and insightful review analytics
      </p>
      <div className="PgIWLBP__tariffs">
        <Tariff {...monthly} category={category} updateLink={updateLink} />
        <Tariff {...yearly} category={category} updateLink={updateLink} />
      </div>
      <ListBenefits />
      <p className="PgIWLBP__refund">10-day money-back guarantee</p>
    </Layout>
  ) : null;
};

export default WaListingBuilderPaywall;
