export const TARIFFS = {
  monthly: {
    name: "monthly",
    price: "$19.99",
    period: "/month",
    strikethroughText: "$59",
    buyNow: {
      path: "/checkout/?offerId=lb-ra-monthly",
      text: "Buy now",
    },
  },
  yearly: {
    name: "yearly",
    price: "$99.99",
    period: "/year",
    strikethroughText: "$708",
    buyNow: {
      path: "/checkout/?offerId=lb-ra-yearly",
      text: "Buy now",
    },
  },
};

export const LIST_BENEFITS = [
  {
    text: "100 SEO-Optimized Product Listings",
    tooltip: "Create the most effective listings, starting with an attractive title, catchy bullet points, a more revealing description, and excellent backend keywords"
  },
  {
    text: "100 Monthly AI Generations",
  },
  {
    text: "100 Self-Created Keyword Group",
    tooltip: "Compile unique groups of keywords based on your own criteria"
  },
  {
    text: "100 AI-Powered Review Analyses",
    tooltip: "Get product improvement ideas, enhance your listing, and understand why people buy the product with instant review statistics"
  },
];