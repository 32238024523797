import React from "react";
import { LIST_BENEFITS } from "../index.content";
import SVG_CHECK_MARK_GREEN from "../../../../assets/images/vector/components/Paywalls/check_mark_green.svg";
import Question from "../Question";
import "./index.scss";

const ListBenefits = () => {
  return (
    <div className="PgIWLBP-LB">
      <p className="PgIWLBP-LB__title">included in all pricing plans</p>
      <ul className="PgIWLBP-LB-list">
        {LIST_BENEFITS.map(({ text, tooltip }, i) => (
          <li
            key={text}
            className={`PgIWLBP-LB__item PgIWLBP-LB__item_i${i + 1}`}
          >
            <img
              src={SVG_CHECK_MARK_GREEN}
              alt=""
              className="PgIWLBP-LB__checkMark"
            />
            <p className="PgIWLBP-LB__text">{text}</p>
            {tooltip && <Question tooltip={tooltip} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListBenefits;
