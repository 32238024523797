import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Tariff = (props) => {
  const {
    name,
    period,
    strikethroughText,
    price,
    buyNow: { text, path },
    category,
    updateLink,
  } = props;
  return (
    <div className={`PgIWLBP-T PgIWLBP-T_${name}`}>
      <div className="PgIWLBP-T__name">{name}</div>
      <p className="PgIWLBP-T__price">
        <b>{price}</b>
        {period}
      </p>
      <p className="PgIWLBP-T__discount">
        <strike>{strikethroughText}</strike>
        {period}
      </p>
      <TrackedLink
        classes="PgIWLBP-T__buyNow"
        category={category}
        action={text}
        path={updateLink(path)}
        isChild
      >
        {text}
      </TrackedLink>
    </div>
  );
};

export default Tariff;
