import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Question = ({ tooltip }) => (
  <div className="PgIWLBP-Q">
    <div className="PgIWLBP-Q__tooltip">{tooltip}</div>
  </div>
);

Question.propTypes = {
  tooltip: PropTypes.string,
};

export default Question;
